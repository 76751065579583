/* WARNING: Auto-generated by the import-fonts script. Changes here will be overwritten. */

@font-face {
  font-family: "Cormorant Garamond";
  src: url("./CormorantGaramond-Bold/cormorant-garamond-bold.woff2") format("woff2"),
    url("./CormorantGaramond-Bold/cormorant-garamond-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Cormorant Garamond";
  src: url("./CormorantGaramond-Medium/cormorant-garamond-medium.woff2") format("woff2"),
    url("./CormorantGaramond-Medium/cormorant-garamond-medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}
